a,
a:link,
a:visited {
  text-decoration: none !important;
}

.page {
}

.content {
  padding-top: 10rem;
}

.tile {
  background-color: #7500ff !important;
  color: white;
  min-height: 12rem;
  padding: 2rem;
  text-align: center;
}

.tile-loading {
  color: white;
  height: 6rem;
  width: 6rem;
}



/* ------ */

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #7500ff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-header h1 {
  font-size: larger;
}

.App-link {
  color: #61dafb;
}
